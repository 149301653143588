import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import requestFormSchemas from "../schemas/index";
import axios from "axios";
import { getCountry, leadSubmit, siteKey } from "../.endpoint";
import { ClockLoader } from "react-spinners";
import Swal from "sweetalert2";
import ExampleDoc from "../doc-file/ExampleFile.xlsx";

const formValues = {
  serviceType: "1",
  customerName: null,
  description: null,
  cAddress: null,
  pointOfContact: null,
  pocAddress: null,
  zipCode: null,
  city: "",
  state: null,
  country: "231",
  email: null,
  phoneNumber: null,
  listProduct: "",
  uploadProductlist: null,
  comments: "",
  repairServices: [],
  expeditedOption: "1",
  joinSubscriberList: "1",
};

const RequestForm = () => {
  document.title = "Law Calibration";
  const Redirect = useNavigate();
  const [option, setOption] = useState(1);
  const [addServices, setAddServices] = useState(1);

  const [isActive, setIsActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [subscriber, setSubscriber] = useState(1);
  const [fileError, setFileError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  const [service, setService] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userFiles, setUserFiles] = useState([]);
  const [verfied, setVerfied] = useState(false);
  const [activeMenuSecond, setActiveMenuSecond] = useState(false);
  // const [repairServices, setRepairServices] = useState(1);
  const [repairServicesError, setRepairServicesError] = useState(null);
  const [repairServices, setRepairServices] = useState([]);

  const handleMenuSecond = () => {
    setActiveMenuSecond(!activeMenuSecond);
  };
  const onCaptchaChange = (value) => {
    setVerfied(true);
    setCaptchaValue(value);
  };

  // const handleCheckboxChange = (event) => {
  //   const { value, checked } = event.target;

  //   if (checked) {
  //     setRepairServices([...repairServices, value]);

  //   } else {
  //     setRepairServices(repairServices.filter((item) => item !== value));
  //   }
  // };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedServices;

    if (value === "4") { // "Not Applicable" is selected
      if (checked) {
        updatedServices = ["4"];
      } else {
        updatedServices = [];
      }
    } else {
      if (checked) {
        updatedServices = repairServices.filter(service => service !== "4").concat(value);
      } else {
        updatedServices = repairServices.filter(service => service !== value);
      }
    }

    setRepairServices(updatedServices);
  };

  const [inputFields, setInputFields] = useState([
    {
      description: "",
      make: "",
      model: "",
      serial: "",
      id: "",
      location: "",
      cycle: "",
      notes: "",
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        description: "",
        make: "",
        model: "",
        serial: "",
        id: "",
        location: "",
        cycle: "",
        notes: "",
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };


  const handleChange2 = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [conId, setConId] = useState("231");
  const handleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const handleCancel = () => {
    window.top.location.href = "https://www.lawcalibration.com";
  };

  useEffect(() => {
    setLoading(true);

    getcountry();
    getState();
    setLoading(false);
    wrapperActive();
  }, [conId]);

  //Call api to get country
  const getcountry = async () => {
    const rescountry = await fetch(getCountry);
    const rescon = await rescountry.json();
    setCountry(rescon.data);
  };

  //call api to get state
  const getState = async () => {
    const resState = await fetch(getCountry + "?id=" + conId);
    const resSt = await resState.json();
    if (resSt.data.length > 0) {
      setState(resSt.data[0].state_list);
    }
  };
  // handle selected country id
  const countryHandler = (event) => {
    const getCountryId = event.target.value;
    setConId(getCountryId);
  };

  const wrapperActive = () => {
    let wrapper = document.querySelectorAll(".col-wrapper select");
    wrapper.forEach((el) => {
      el.addEventListener("pointerdown", function () {
        this.parentNode.classList.toggle("active");
      });

      el.addEventListener("blur", function () {
        this.parentNode.classList.remove("active");
      });
    });
  };

  // form handling through Formik hook
  const { values, touched, errors, ErrorMessage, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: { formValues },
      validationSchema: requestFormSchemas,

      onSubmit: (values, action) => {


        if (!repairServices.length) {
          setRepairServicesError('At least one repair service must be selected');
          console.log('error');
          return false;
        } else {
          setRepairServicesError(null);
          console.log('Submit');

        }

        if (fileError) {
          return false;
        }
        if (!verfied) {
          Swal.fire(
            "Google Captcha Error",
            "Please verify google captcha?",
            "error"
          );
          return false;
        }

        values.country = conId;
        values.uploadProductlist = userFiles;
        setOption(values.expeditedOption);
        setAddServices(values.additionalServices);
        setSubscriber(values.joinSubscriberList);
        setService(values.serviceType);

        const formData = new FormData();
        formData.append("serviceType", service);
        formData.append("customerName", values.customerName ?? "");
        formData.append("cAddress", values.cAddress ?? "");
        formData.append("pointOfContact", values.pointOfContact ?? "");
        formData.append("pocAddress", values.pocAddress ?? "");
        formData.append("zipCode", values.zipCode ?? "");

        // JSON.stringify()
        formData.append(
          "productListAr",
          JSON.stringify(
            inputFields.length == 1 && !inputFields[0].description
              ? []
              : inputFields
          )
        );
        formData.append("city", values.city ?? "");
        formData.append("state", values.state ?? "");
        formData.append("country", values.country ?? "");
        formData.append("email", values.email ?? "");
        formData.append("phoneNumber", values.phoneNumber ?? "");
        formData.append("listProduct", values.listProduct ?? "");
        formData.append("uploadProductlist", userFiles);
        formData.append("comments", values.comments ?? "");
        formData.append("expeditedOption", option);
        formData.append("additionalServices", addServices);
        formData.append("repairServices", repairServices);
        formData.append("joinSubscriberList", subscriber);
        formData.append("recaptcha", captchaValue);

        setLoading(true);
        axios
          .post(leadSubmit, formData)
          .then((res) => {
            console.log('res--', res);
            if (res.data.success) {
              setInputFields([
                {
                  description: "",
                  make: "",
                  model: "",
                  serial: "",
                  id: "",
                  location: "",
                  cycle: "",
                  notes: "",
                },
              ]);
              setLoading(false);
              setUserFiles([]);
              // Redirect("/thanks"); //https://www.lawcalibration.com/thank-you/
              window.top.location.href = "https://www.lawcalibration.com/thank-you/";
            } else {
              console.log("fails-", res.data);
              setLoading(false);
              if (
                res.data.data.uploadProductlist &&
                res.data.data.uploadProductlist.length > 0
              ) {
                setFileError(res.data.data.uploadProductlist[0]);
                return false;
              } else if (
                res.data.data.code_match_not &&
                res.data.data.code_match_not.length > 0
              ) {
                Swal.fire(
                  "Validation Error",
                  res.data.data.code_match_not,
                  "error"
                );
                return false;
              } else {
                Swal.fire(
                  "Validation Error",
                  "Please enter valid inputs",
                  "error"
                );
                return false;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
        action.resetForm({ values: formValues });
      },
    });

  const hanldeClick = () => {
    // window.scrollTo({
    //     top: document.querySelector("#navbar").offsetHeight
    // })
  };

  const handleFileValidation = (file) => {
    if (file) {
      const fileSize = file.size;
      const fileType = file.type;
      //.pdf,.csv,.doc,.xls,.xlsx,.docx

      const allowExtentions = [
        "text/csv",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/msword",
      ];

      if (!allowExtentions.includes(fileType)) {
        setFileError("Please select a valid file");
      } else if (fileSize > 2111740) {
        setFileError("Only documents up to 2MB are permitted.");
      } else {
        setFileError("");
      }
    } else {
      setFileError("");
    }
  };

  const handlePaste = (event) => {
    // Get the pasted content
    const pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    // Check if the pasted content contains a link or a tag
    if (containsLinkOrTag(pastedText)) {
      event.preventDefault();
      Swal.fire("Invalid Text", "Link and tags are not allowed", "error");
    }
  };

  const containsLinkOrTag = (text) => {
    // Simple check for http/https or tags in the text
    const linkRegex = /(http:\/\/|https:\/\/)/;
    const tagRegex = /<\/?[a-z][\s\S]*>/i;
    return linkRegex.test(text) || tagRegex.test(text);
  };


  return (
    <>
      <div className="req-form-sec">
        {loading ? (
          <div className="loader-Container">
            <ClockLoader color="#36d7b7" />
          </div>
        ) : (
          <>

            <div className="container">
              <div className="req-banner-content">
                <form
                  id="req-form"
                  className="form-horizontal"
                  encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <div className="req-row">
                    <div className="form-group w-100">
                      <label htmlFor="subscriber">Service Type</label>
                      <div className="radio-group">
                        <div className="radio i-checks">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              onChange={(e) => {
                                setService(e.target.value);
                              }}
                              name="serviceType"
                              checked={service == "1"}
                            />{" "}
                            <i></i> In-Lab Request
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            <input
                              type="radio"
                              value="2"
                              onChange={(e) => {
                                setService(e.target.value);
                              }}
                              name="serviceType"
                              checked={service == "2"}
                            />{" "}
                            <i></i>On-Site Request
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            <input
                              type="radio"
                              value="3"
                              onChange={(e) => {
                                setService(e.target.value);
                              }}
                              name="serviceType"
                              checked={service == "3"}
                            />{" "}
                            <i></i> Combined Service Request
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-wrapper">
                        <input
                          type="text"
                          id="customerName"
                          name="customerName"
                          placeholder="Company Name"
                          maxLength={50}
                          className="form-control"
                          value={values.customerName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (new RegExp(/[a-zA-Z ]/).test(e.key)) {
                            } else e.preventDefault();
                          }}
                        />
                        {(errors.customerName && touched.customerName) ||
                          (errors.customerName && touched.formValues) ? (
                          <p className="text-danger">{errors.customerName}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-wrapper">
                        <input
                          name="pointOfContact"
                          id="pointOfContact"
                          type="text"
                          maxLength={50}
                          placeholder="Point of Contact"
                          className="form-control"
                          value={values.pointOfContact}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (new RegExp(/[a-zA-Z ]/).test(e.key)) {
                            } else e.preventDefault();
                          }}
                        />
                        {(errors.pointOfContact && touched.pointOfContact) ||
                          (errors.pointOfContact && touched.formValues) ? (
                          <p className="text-danger">{errors.pointOfContact}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-wrapper">
                        <input
                          name="pocAddress"
                          id="pocAddress"
                          type="text"
                          placeholder="Address"
                          maxLength={100}
                          className="form-control"
                          value={values.pocAddress}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const { value } = e.target;

                            // Check if the first character is ',' or '.'
                            if (
                              value.startsWith(",") ||
                              value.startsWith(".") ||
                              value.startsWith(" ")
                            ) {
                              // Remove the first character if it's ',' or '.'
                              const newValue = value.substring(1);
                              handleChange({
                                target: { name: "pocAddress", value: newValue },
                              });
                            } else {
                              handleChange(e);
                            }
                          }}
                        />
                        {(errors.pocAddress && touched.pocAddress) ||
                          (errors.pocAddress && touched.formValues) ? (
                          <p className="text-danger">{errors.pocAddress}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-wrapper">
                        <input
                          name="city"
                          id="city"
                          type="text"
                          placeholder="City"
                          maxLength={100}
                          className="form-control"
                          value={values.city}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (new RegExp(/[a-zA-Z ]/).test(e.key)) {
                            } else e.preventDefault();
                          }}
                        />
                        {(errors.city && touched.city) ||
                          (errors.city && touched.formValues) ? (
                          <p className="text-danger">{errors.city}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-wrapper">
                        <select
                          className="form-control m-b"
                          value={values.state}
                          name="state"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option>Select State</option>
                          {state.map((getSt, index) => (
                            <option key={index} value={getSt.id}>
                              {getSt.state_name}
                            </option>
                          ))}
                        </select>
                        <div className="icon">
                          <i className="fa fa-angle-down"></i>
                        </div>
                      </div>
                      {(errors.state && touched.state) ||
                        (errors.state && touched.formValues) ? (
                        <p className="text-danger">{errors.state}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <div className="col-wrapper">
                        <input
                          name="zipCode"
                          id="zipCode"
                          type="text" // Change type to text
                          placeholder="Zip Code"
                          className="form-control"
                          maxLength={6}
                          value={values.zipCode}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const { value } = e.target;

                            // Regular expression to allow only numbers
                            const onlyNumbers = /^[0-9]*$/;

                            // Check if the input is a number or empty
                            if (onlyNumbers.test(value) || value === "") {
                              // Check if the input starts with 0
                              if (value.startsWith("0") && value.length === 1) {
                                handleChange({
                                  target: { name: "zipCode", value },
                                });
                              }

                              // Check the length of the input
                              else if (value.length <= 5) {
                                handleChange({
                                  target: { name: "zipCode", value },
                                });
                              }
                            }
                          }}
                          onKeyPress={(e) => {
                            // Prevent entering characters other than numbers
                            const charCode = e.which ? e.which : e.keyCode;

                            if (charCode < 48 || charCode > 57) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {(errors.zipCode && touched.zipCode) ||
                          (errors.zipCode && touched.formValues) ? (
                          <p className="text-danger">{errors.zipCode}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-wrapper">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          id="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {(errors.email && touched.email) ||
                          (errors.email && touched.formValues) ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      {/* <label htmlFor='phoneNumber' >PHONE NUMBER <sup >*</sup></label> */}
                      <div className="col-wrapper">
                        <input
                          name="phoneNumber"
                          id="phoneNumber"
                          type="text" // Change type to text
                          placeholder="Phone Number"
                          className="form-control"
                          value={values.phoneNumber}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const { value } = e.target;

                            // Regular expression to allow only numbers
                            const onlyNumbers = /^[0-9]*$/;

                            // Check if the input is a number or empty
                            if (onlyNumbers.test(value) || value === "") {
                              // Check the length of the input
                              if (value.length <= 10) {
                                handleChange({
                                  target: { name: "phoneNumber", value },
                                });
                              }
                            }
                          }}
                          onKeyPress={(e) => {
                            // Prevent entering characters other than numbers
                            const charCode = e.which ? e.which : e.keyCode;

                            if (charCode < 48 || charCode > 57) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {(errors.phoneNumber && touched.phoneNumber) ||
                          (errors.phoneNumber && touched.formValues) ? (
                          <p className="text-danger">{errors.phoneNumber}</p>
                        ) : null}
                      </div>
                    </div>

                    {/* Dynamic fields  */}
                    <div className="form-group">
                      <label>Equipment Serviced</label>
                      <div
                        className="col-wrapper abc"
                        style={{ marginTop: "8px" }}
                      >
                        {inputFields.map((data, index) => {
                          const {
                            description,
                            make,
                            model,
                            serial,
                            id,
                            location,
                            cycle,
                            notes,
                          } = data;
                          return (
                            <React.Fragment>
                              <div className="inner-row" key={index}>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={make}
                                      name="make"
                                      className="form-control"
                                      placeholder="Make"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={model}
                                      name="model"
                                      className="form-control"
                                      placeholder="Model"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={serial}
                                      name="serial"
                                      className="form-control"
                                      placeholder="Serial"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={id}
                                      name="id"
                                      className="form-control"
                                      placeholder="ID"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={location}
                                      name="location"
                                      className="form-control"
                                      placeholder="Location"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={cycle}
                                      name="cycle"
                                      className="form-control"
                                      placeholder="cycle"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={notes}
                                      name="notes"
                                      className="form-control"
                                      placeholder="Notes"
                                      maxLength={50}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z ]/).test(e.key)
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="inner-col full-col">
                                  <div className="form-group">
                                    <textarea
                                      type="text"
                                      onChange={(e) => handleChange2(index, e)}
                                      value={description}
                                      name="description"
                                      className="form-control descriptionabc"
                                      placeholder="Description (Max 250 Characters)"
                                      maxLength={250}
                                      onPaste={(e) => handlePaste(e)}
                                      onKeyDown={(e) => {
                                        if (
                                          new RegExp(/[0-9a-zA-Z(.), ]/).test(
                                            e.key
                                          )
                                        ) {
                                        } else e.preventDefault();
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="inner-col">
                                  {inputFields.length !== 1 ? (
                                    <div
                                      className="btn-close"
                                      onClick={(e) =>
                                        removeInputFields(index, e)
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {/* <span className='error'>Error Message</span> */}
                            </React.Fragment>
                          );
                        })}

                        <div className="mt-4 text-end">
                          <div
                            className="btn btn-primary"
                            onClick={addInputField}
                          >
                            Add More
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Dynamic fields  */}

                    <div className="form-group w-100">
                      <div className="form-file-box">
                        <label htmlFor="uploadProductlist">
                          Upload Product List
                        </label>

                        <div className="example-file-link">
                          <Link
                            to={ExampleDoc}
                            download="ExampleFile"
                            target="_block"
                          >
                            Download Intake Form
                          </Link>
                        </div>
                      </div>

                      <div
                        className="col-wrapper"
                        style={{
                          borderBottom: "1.5px solid",
                          paddingBlockEnd: "24px",
                        }}
                      >
                        {/* <input type="file" style={{ border: 'none' }} name='uploadProductlist' id='uploadProductlist' className="form-control" value={values.uploadProductlist} onBlur={handleBlur} onChange={(event) => setUserFiles(event.target.files[0])} /> */}
                        <input
                          type="file"
                          style={{ border: "none" }}
                          name="uploadProductlist"
                          id="uploadProductlist"
                          className="form-control"
                          value={values.uploadProductlist}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setUserFiles(e.target.files[0]);
                            handleFileValidation(e.target.files[0]);
                          }} //handleFileValidation(e.target.files[0]);
                          accept=".pdf,.csv,.doc,.xls,.xlsx,.docx"
                        />

                        {fileError ? (
                          <p className="text-danger">{fileError}</p>
                        ) : null}
                        <div className="warning-text">
                          <p>
                            Only documents (pdf, csv, doc, xls, xlsx, docx) up
                            to 2MB are permitted
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-wrapper">
                        <textarea
                          name="comments"
                          className="form-control"
                          placeholder="Comment (Max 250 Characters)"
                          id="comments"
                          cols="90"
                          rows="5"
                          maxLength={250}
                          value={values.comments}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></textarea>
                        {errors.comments ? (
                          <p className="text-danger">{errors.comments}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group w-100">
                      <label htmlFor="expeditedOption">Expedited Options</label>
                      <div className="col-wrapper radio-group">
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              value="1"
                              name="expeditedOption"
                              checked={option == "1"}
                            />{" "}
                            <i></i> 24 Hour Rush (in lab) (150/product)
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              value="2"
                              name="expeditedOption"
                              checked={option == "2"}
                            />{" "}
                            <i></i> 48 Hour Rush (in lab) (125/product)
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              value="3"
                              name="expeditedOption"
                              checked={option == "3"}
                            />{" "}
                            <i></i> Same Day Rush (on site) (150% charge
                            overall){" "}
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              value="4"
                              name="expeditedOption"
                              checked={option == "4"}
                            />{" "}
                            <i></i> One Week Rush (in lab) (50/product){" "}
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              value="5"
                              name="expeditedOption"
                              checked={option == "5"}
                            />{" "}
                            <i></i> One Week Rush (on site) (75/product){" "}
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setOption(e.target.value);
                              }}
                              value="6"
                              name="expeditedOption"
                              checked={option == "6"}
                            />{" "}
                            <i></i> Standard Service{" "}
                          </label>
                        </div>
                        {errors.expeditedOption ? (
                          <p className="text-danger">
                            {errors.expeditedOption}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group w-100">
                      <label htmlFor="additionalServices">
                        Additional Services
                      </label>
                      <div className="col-wrapper radio-group">
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setAddServices(e.target.value);
                              }}
                              value="1"
                              name="additionalServices"
                              checked={addServices == "1"}
                            />{" "}
                            <i></i> After Hours 150%/hour
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setAddServices(e.target.value);
                              }}
                              value="2"
                              name="additionalServices"
                              checked={addServices == "2"}
                            />{" "}
                            <i></i> Weekends/Holidays 200%/hour
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setAddServices(e.target.value);
                              }}
                              value="3"
                              name="additionalServices"
                              checked={addServices == "3"}
                            />{" "}
                            <i></i> Metrologist 250/hour{" "}
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setAddServices(e.target.value);
                              }}
                              value="4"
                              name="additionalServices"
                              checked={addServices == "4"}
                            />{" "}
                            <i></i> Not Applicable{" "}
                          </label>
                        </div>
                        {errors.additionalServices ? (
                          <p className="text-danger">
                            {errors.additionalServices}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* Repair Services start */}
                    <div className="form-group w-100">
                      <label htmlFor="repair">Repair Services + Calibration Charges</label>
                      <div className="col-wrapper radio-group">
                        {/* Checkbox fields */}
                        <div className="checkbox i-checks">
                          <label>
                            <input
                              type="checkbox"
                              name="repairCheck"
                              onClick={handleCheckboxChange}
                              value="1"
                              checked={repairServices.includes("1")}
                            />
                            <i></i> Evaluation $ 95.00
                          </label>
                        </div>
                        <div className="checkbox i-checks">
                          <label>
                            <input
                              type="checkbox"
                              name="repairCheck"
                              onClick={handleCheckboxChange}
                              value="2"
                              checked={repairServices.includes("2")}
                            />
                            <i></i> Repair/Service 125/hour + parts
                          </label>
                        </div>
                        <div className="checkbox i-checks">
                          <label>
                            <input
                              type="checkbox"
                              name="repairCheck"
                              onClick={handleCheckboxChange}
                              value="3"
                              checked={repairServices.includes("3")}
                            />
                            <i></i> Cleaning Fee $50.00
                          </label>
                        </div>
                        <div className="checkbox i-checks">
                          <label>
                            <input
                              type="checkbox"
                              name="repairCheck"
                              onClick={handleCheckboxChange}
                              value="4"
                              checked={repairServices.includes("4")}
                            />
                            <i></i> Not Applicable
                          </label>
                        </div>
                      </div>

                      {!repairServices.length && repairServicesError !== null ? (
                        <p className="text-danger">{repairServicesError}</p>
                      ) : null}
                      {/* <pre>{JSON.stringify(repairServices, null, 2)}</pre> */}
                    </div>
                    {/* Repair Services end */}

                    <div className="form-group w-100">
                      <label htmlFor="subscriber">
                        Join Email Subscriber List?
                      </label>
                      <div className="col-wrapper radio-group">
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setSubscriber(e.target.value);
                              }}
                              value="1"
                              name="joinSubscriberList"
                              checked={subscriber == "1"}
                            />{" "}
                            <i></i>Yes
                          </label>
                        </div>
                        <div className="radio i-checks">
                          <label>
                            {" "}
                            <input
                              type="radio"
                              onChange={(e) => {
                                setSubscriber(e.target.value);
                              }}
                              value="0"
                              name="joinSubscriberList"
                              checked={subscriber == "0"}
                            />{" "}
                            <i></i>No
                          </label>
                        </div>
                        {errors.joinSubscriberList ? (
                          <p className="text-danger">
                            {errors.joinSubscriberList}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="g-reCAPTCHA">
                        <ReCAPTCHA
                          sitekey={siteKey}
                          onChange={onCaptchaChange}
                        />
                      </div>
                    </div>

                    <div className="form-group" style={{ alignSelf: "center" }}>
                      <div
                        className="col-wrapper group-btns"
                        style={{ justifyContent: "end" }}
                      >
                        <button
                          className="btn btn-white"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={hanldeClick}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RequestForm;
