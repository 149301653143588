import { useState } from "react"
function AddRemoveInputField() {

    const [inputFields, setInputFields] = useState([{
        description: '',
        make: '',
        serial: '',
        id: '',
        Location: '',
        cycle: '',

    }]);

    const addInputField = () => {
        setInputFields([...inputFields, {
            description: '',
            make: '',
            serial: '',
            id: '',
            location: '',
            cycle: '',
        }])

    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }
    return (

        <div className="form-group">
            <div className="col-wrapper">
                <div className="col-sm-12">
                    {
                        inputFields.map((data, index) => {
                            const { fullName, emailAddress, salary } = data;
                            return (
                                <div className="row my-3" key={index}>
                                    <div className="col">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                onChange={(evnt) => handleChange(index, evnt)}
                                                value={fullName}
                                                name="description"
                                                className="form-control"
                                                placeholder="Description" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text"
                                                onChange={(evnt) => handleChange(index, evnt)}
                                                value={fullName}
                                                name="make"
                                                className="form-control"
                                                placeholder="Make" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={fullName} name="serial" className="form-control" placeholder="Serial" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={fullName} name="id" className="form-control" placeholder="ID" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={fullName} name="location" className="form-control" placeholder="Location" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={fullName} name="cycle" className="form-control" placeholder="cycle" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        {(inputFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button> : ''}
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-sm-12">
                            <button onClick={addInputField}>Add New</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-sm-4">

            </div> */}
        </div>

    )
}
export default AddRemoveInputField