import React from 'react'
import { Link } from 'react-router-dom'
import { userInfo, userName } from '../.comman'


function Navbar() {

    return (
        <>
            <div className="row border-bottom">
                <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: "0" }}>
                    <div className="navbar-header">
                        {/* <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#"><i className="fa fa-bars"></i> </a> */}

                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                        <li>
                            {/* <span className="m-r-sm text-muted welcome-message">Welcome to {userName()}</span> */}
                        </li>
                        <li>
                            <Link to={'/logout'}>
                                <i className="fa fa-sign-out"></i> Log out
                            </Link>
                        </li>
                    </ul>

                </nav>
            </div>
        </>
    )
}

export default Navbar
