import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../Layout/Navbar'
import LeftNavbar from '../Layout/LeftNavbar'
import Footer from '../Layout/Footer'
import { Link, NavLink } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios';
import { downloadCsvLink, getActiveSalePerson, getAllLeads, getLeadList, getSalePersonList, getStatus, leadDelete } from '../../.endpoint'
import { getToken, isAdmin, toastSetting } from '../.comman'
import { CSVLink } from 'react-csv'
import { useTransition } from 'react'
import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';


const LeadListing = () => {
    document.title = "Law Calibration -Leads List";
    const [items, setItems] = useState([]);
    // const [isPending, startTransition] = useTransition();
    const [loading, setLoading] = useState(false);
    const [dataToDownload, setDataToDownload] = useState([]);
    const [salePersonList, setSalePersonList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [salePersonFilterVal, setSalePersonFilterVal] = useState('');
    const [statusFilterVal, setStatusFilterVal] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [pageCount, setPageCount] = useState();
    const [lastRecord, setLastRecord] = useState(10);
    const csvDownloadref = useRef(null);

    const setPegination = (res) => {
        const total = (res.data.data.total);
        setTotalRecords(total);
        const pageLimit = (res.data.data.per_page);
        setPageCount(Math.ceil(total / pageLimit));
        setPageSize(pageLimit);
    }

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageNo(currentPage);
        fetchData(currentPage);
        if (totalRecords > (pageSize * currentPage)) {
            setLastRecord(pageSize * currentPage)
        } else {
            setLastRecord(totalRecords)
        }

    }
    const fetchDataToDownload = async () => {
        const apiUrl = getAllLeads + '?search=' + searchKey
            + '&sp=' + salePersonFilterVal + '&status=' + statusFilterVal
            + '&sd=' + startDate + '&ed=' + endDate;

        console.log('apiUrl-->', apiUrl);
        await axios.get(apiUrl, { headers: headers })
            .then((res) => {
                setDataToDownload(res.data.data);
            })
            .catch((error) => { console.log(error); })
        setTimeout(() => { csvDownloadref.current.link.click() }, 1500);
    }

    const handleStartDateChange = (startDate) => {
        console.log('startDate', startDate);
        setStartDate(startDate.toLocaleDateString('en-CA'));
    }
    const handleEndDateChange = (endDate) => {
        setEndDate(endDate.toLocaleDateString('en-CA'));
    }

    const handleClearFilters = () => {
        setEndDate('');
        setStartDate('');
        setSearchKey('');
        setSalePersonFilterVal('');
        setStatusFilterVal('');
    }


    useEffect(() => {
        fetchData(1); // Call dataFilter after setting the state
    }, [statusFilterVal, salePersonFilterVal, searchKey, startDate, endDate]);

    // const dataFilter = async () => {

    //     axios.get(getLeadList + '?sp=' + salePersonFilterVal + '&search=' + searchKey + '&status=' + statusFilterVal, { headers: headers })
    //         .then((res) => {
    //             setPegination(res);
    //             setItems(res.data.data.data);
    //         })
    //         .catch((error) => { console.log(error); })
    // }

    const csvHeader = [
        { label: 'Service Type', key: 'service_type_name' },
        { label: 'Company Name', key: 'customer_name' },
        { label: 'Point Of Contact', key: 'point_of_contact' },
        { label: 'Address', key: 'poc_address' },
        { label: 'City', key: 'city_name' },
        { label: 'State', key: 'state_name' },
        { label: 'Zip-code', key: 'zip_code' },
        { label: 'Email', key: 'email' },
        { label: 'Phone No', key: 'phone_no' },
        { label: 'Product List File', key: 'pdt_file' },
        { label: 'Comments', key: 'comments' },
        { label: 'Expedited Options', key: 'expedited_options_name' },
        { label: 'Join Mail List', key: 'subscriber_list' },
        { label: 'Sales Person', key: 'sales_man_name' },
    ]
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    useEffect(() => {
        setLoading(true)
        axios.get(getLeadList, { headers: headers })
            .then((res) => {
                setPegination(res);
                setItems(res.data.data.data);
                setLoading(false);
            })
            .catch((error) => { console.log(error); })
    }, [])

    useEffect(() => {
        getSalePerson();
        getStatusList();
    }, [])


    //Fetch status list 
    const getStatusList = () => {
        axios.get(getStatus, { headers: headers })
            .then((res) => {
                setStatusList(res.data.data);
            })
            .catch((error) => { console.log('Error--', error.message); })
    }

    //Fetch sale person list 
    const getSalePerson = () => {
        axios.get(getActiveSalePerson, { headers: headers })
            .then((res) => {
                setSalePersonList(res.data.data);
            })
            .catch((error) => { console.log('Error--', error.message); })
    }

    //Get request lead records
    const fetchData = (currentPage) => {
        console.log({
            "SP": salePersonFilterVal,
            "S": statusFilterVal,
            "SK": searchKey,
            "SD": startDate,
            "ED": endDate
        });
        const apiUrl = getLeadList + "?page=" + currentPage + '&search=' + searchKey
            + '&sp=' + salePersonFilterVal + '&status=' + statusFilterVal
            + '&sd=' + startDate + '&ed=' + endDate;

        console.log('apiUrl-->', apiUrl);
        axios.get(apiUrl, { headers: headers })
            .then((res) => {

                setPegination(res);
                setItems(res.data.data.data);
            })
            .catch((error) => { console.log(error); })
    }

    //Get request lead records with search key
    // const getSearch = (searchKeyWord) => {
    //     axios.get(getLeadList + "?search=" + searchKeyWord + "&sp=" + salePersonFilterVal, { headers: headers })
    //         .then((res) => {
    //             setPegination(res);
    //             setItems(res.data.data.data);
    //         })
    //         .catch((error) => { console.log(error); })
    // }

    //Delete Request leads function
    const handleDeleteEvent = (recordId) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get(leadDelete + "?leadId=" + recordId, { headers: headers })
                    .then((res) => {
                        fetchData(1);
                        if (res.data.message == 'Validation Failed') {
                            const errorMessages = Object.values(res.data.data).flat();
                            toast.error(errorMessages.join(', '), toastSetting());
                        } else {
                            toast.success(res.data.message, toastSetting())
                        }
                    })
                    .catch((error) => {

                        if (error.data.message == 'Validation Failed') {
                            const errorMessages = Object.values(error.data.message).flat();
                            toast.error(errorMessages.join(', '), toastSetting());
                        } else {
                            toast.error(error.data.message, toastSetting());
                        }
                    })

            }
        })
    }

    //function for csv download
    const downloadCsv = async () => {
        try {

            const downloadApiUrl = downloadCsvLink + '?search=' + searchKey
                + '&sp=' + salePersonFilterVal + '&status=' + statusFilterVal
                + '&sd=' + startDate + '&ed=' + endDate;

            const response = await axios.get(downloadApiUrl, {
                headers: headers,
                responseType: 'blob' // Important: Set the response type to blob
            });

            const currentDate = new Date();
            const timestamp = currentDate.toISOString().replace(/[-:]/g, '').replace('T', '').slice(0, -5);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `RequestLeads${timestamp}.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    return (
        <div id="wrapper">

            <LeftNavbar />

            <div id="page-wrapper" className="gray-bg">
                <Navbar />
                <div className="wrapper wrapper-content">
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            <h2>Lead Listing</h2>
                            <ol className="breadcrumb">
                                <li>
                                    <NavLink to={'/dashboard'}>Home</NavLink>
                                </li>
                                <li>
                                    <a>Tables</a>
                                </li>
                                <li className="active">
                                    <strong>Leads</strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title custom_title">
                                        {/* <h5>Leads Tables</h5> */}
                                        <div className="lead-filters-wrapper">
                                            <div className="item large">
                                                <div className="input-group">
                                                    <div htmlFor='search' className="input-text">
                                                        <span>Search</span>
                                                    </div>
                                                    <input
                                                        className="form-control m-b"
                                                        onChange={(e) => setSearchKey(e.target.value)}
                                                        value={searchKey}
                                                        type="text"
                                                        id='search'
                                                        name="search"
                                                        placeholder='Company Name/ Point of Contact or Phone No.' />
                                                </div>
                                            </div>

                                            <div className="item date-item" style={{ flexGrow: 1 }}>
                                                <div className="input-group">
                                                    <DatePicker
                                                        name='startDate'
                                                        selected={startDate}
                                                        className="form-control m-b"
                                                        onChange={handleStartDateChange}
                                                        dateFormat="MMM d, yyyy"
                                                        maxDate={new Date()}
                                                        placeholderText="Select Start Date"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item date-item" style={{ flexGrow: 1 }}>
                                                <div className="input-group">
                                                    <DatePicker
                                                        name='endDate'
                                                        selected={endDate}
                                                        className="form-control m-b"
                                                        onChange={handleEndDateChange}
                                                        dateFormat="MMM d, yyyy"
                                                        maxDate={new Date()}
                                                        placeholderText="Select End Date"

                                                    />
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="input-group">
                                                    <select
                                                        className="form-control m-b"
                                                        name="statusId"
                                                        value={statusFilterVal}
                                                        onChange={(e) => setStatusFilterVal(e.target.value)}
                                                    >
                                                        <option value="">Select Status</option>
                                                        {statusList.map((statusObj, index) => (
                                                            <option key={index} value={statusObj.name}>{statusObj.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {isAdmin() && (
                                                <div className="item">
                                                    <div className="input-group">
                                                        <select
                                                            className="form-control m-b"
                                                            name="salePersonId"
                                                            value={salePersonFilterVal}
                                                            onChange={(e) => setSalePersonFilterVal(e.target.value)}
                                                        >
                                                            <option value="">Select Sale Person</option>
                                                            {salePersonList.map((getPerson, index) => (
                                                                <option key={index} value={getPerson.id}>{getPerson.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                            }
                                            <div className="item btn-action">
                                                {/* <CSVLink
                                                    data={dataToDownload}
                                                    headers={csvHeader}
                                                    filename='Request_data_list.csv'
                                                    className='hidden'
                                                    ref={csvDownloadref}
                                                    target='_blank'
                                                />
                                                <button type="button" onClick={() => fetchDataToDownload()} className="btn btn-outline btn-primary">Download CSV</button> */}
                                                <button type="button" onClick={downloadCsv} className="btn btn-outline btn-primary">Download CSV</button>
                                            </div>

                                            <div className="item btn-action">
                                                <button onClick={handleClearFilters} className='btn btn-filter btn-outline btn-primary'>Clear Filters</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="ibox-content">


                                        <table className="table table-striped table-bordered table-hover dataTables-example" >
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Company Name</th>
                                                    <th>Point of Contact</th>
                                                    <th>Phone No.</th>
                                                    <th>Email</th>
                                                    <th>City</th>
                                                    <th>Sale Person</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {

                                                    items.length > 0 ? (
                                                        items && items.map((item, i) => {
                                                            return (<tr className="gradeC" key={i}>
                                                                <td>{((pageNo - 1) * 10 + (i + 1))}</td>
                                                                <td>{item.customer_name}</td>
                                                                <td>{item.point_of_contact}</td>
                                                                <td>{item.phone_no}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.city_name}</td>
                                                                <td>{item.sales_man_name}</td>
                                                                <td>{item.status}</td>
                                                                <td>{item.created_date}</td>
                                                                <td><Link to={"/lead-detail/" + item.id} > <button className="btn btn-outline btn-primary dim" type="button"><i className="fa fa-eye"></i></button></Link>
                                                                    {isAdmin() ? (
                                                                        <button onClick={() => handleDeleteEvent(item.id)} className="btn btn-outline btn-danger  dim " type="button"><i className="fa fa-trash"></i></button>
                                                                    ) : null}
                                                                </td>
                                                            </tr>)
                                                        })
                                                    ) : (
                                                        <tr className="gradeC">
                                                            <td colSpan={9}>{loading ? <p>Loading...</p> : "Data Not available"}</td>
                                                        </tr>
                                                    )
                                                }


                                            </tbody>
                                        </table>
                                        <div className='pagination-Wrapper'>

                                            <ReactPaginate
                                                previousLabel={'<<'}
                                                nextLabel={'>>'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                // marginPagesDisplayed={2}
                                                // pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={'pagination'}
                                                pageClassName={'paginate_button page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'paginate_button page-item previous'}
                                                nextClassName={'paginate_button page-item next'}
                                                previousLinkClassName={'page-link'}
                                                nextLinkClassName={'page-link'}
                                                breakClassName={'page-link'}
                                                breakLinkClassName={'page-link'}
                                                activeClassName={'active'}
                                            />

                                            {/* <p>Showing {((pageNo * pageSize) - pageSize) + 1} to {(pageNo * pageSize)} of {totalRecords} records</p> */}
                                            <p>Showing {((pageNo * pageSize) - pageSize) + 1} to {lastRecord} of {totalRecords} records</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div >
    )
}

export default LeadListing
