import * as yup from 'yup'

const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'


const AddStatusSchemas = yup.object({
    name: yup.string()
        .min(2, 'Status name must be at least 2 characters')
        .max(50, 'Status name must be at most 50 characters')
        .matches(stringcheck, 'Please enter valid status name. (Only alphabets are allowed)')
        .required('Please enter status name'),

    status: yup.string()
        .required('Please choose status'),
});
export default AddStatusSchemas;