import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function ThanksPage() {
    document.title = "Law Calibration -Thankyou";

    const [isActive, setIsActive] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const [activeMenuSecond, setActiveMenuSecond] = useState(false);
    const handleMenuSecond = () => {
        setActiveMenuSecond(!activeMenuSecond)
    }
    const handleMenu = () => {
        setActiveMenu(!activeMenu)
    }
    const handleOkClick = () => {
        setRedirect(true);
    };

    if (redirect) {
        window.top.location.href = 'https://www.lawcalibration.com';
        return null; // Render nothing if redirection is in progress
    }


    return (
        <>



            <div className="inner-banner">
                <div className="inner-banner-image">
                    <img src="./asset/img/contact-bg.jpg" alt="" />
                </div>
                <div className="container">
                    <div className="inner-banner-content">
                        <div className="card wrapper-content">
                            <div className='custom-card'>
                                <h1>WE HAVE RECEIVED YOUR QUOTE REQUEST</h1>
                                <h3>Thank you for submitting your request. One of our customer care specialists will respond to you shortly. We appreciate your business.</h3>
                                <h3><b>Thank you</b></h3>
                                {/* <NavLink to={'https://www.lawcalibration.com'}>OK</NavLink> */}
                                <button className='btn-ok' onClick={handleOkClick}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}
