import React from 'react'
import LeftNavbar from '../Layout/LeftNavbar'
import Navbar from '../Layout/Navbar'
import { NavLink, useNavigate } from 'react-router-dom'
import Footer from '../Layout/Footer'
import { useFormik } from 'formik'
import axios from 'axios'
import { addSalePerson, addStatus } from '../../.endpoint'
import { getToken, toastSetting } from '../.comman'
import { toast } from 'react-toastify';
import AddStatusSchemas from '../../schemas/AddStatusSchemas'
// import 'react-toastify/dist/ReactToastify.css';
// import ToastContainerDefault from '../ToastContainerDefault'

const formValues = {
    name: "",
    status: "1"
};
export default function AddStatus() {
    document.title = "Add Status | Law Calibration";
    const navigate = useNavigate();
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: { formValues },
        validationSchema: AddStatusSchemas,
        onSubmit: (values, action) => {
            try {
                axios.post(addStatus, values, { headers: headers })
                    .then((res) => {
                        if (res.data.success === true) {
                            console.log('Data--', res.data);
                            toast.success(res.data.message, toastSetting())
                            setTimeout(() => {
                                action.resetForm({ values: formValues });
                                navigate("/status/list");
                            }, 2000)
                        } else if (res.data.success === false) {
                            if (res.data.data.name && res.data.data.name.length > 0) {
                                console.log('Data--', res.data);
                                toast.error(res.data.data.name[0], toastSetting())
                                return false;
                            }
                        }
                    })
                    .catch((error) => { toast.error(error.message, toastSetting()); })
                // action.resetForm({ values: formValues });
            } catch (error) {
                console.log('error', error);
                toast.error(error.message, toastSetting());
            }
        }
    });

    return (
        <>
            <div id="wrapper">
                <LeftNavbar />

                <div id="page-wrapper" className="gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Add Status</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/status/list'}>Status List</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>Add New</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2">

                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Add Status Form</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <form className="form-horizontal" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor='name' className="col-sm-2 control-label">Name <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            id='name' name='name'
                                                            className="form-control"
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {((errors.name && touched.name) || (errors.name && touched.formValues)) ? (<p className='text-danger'>{errors.name}</p>) : null}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor='status' className="col-sm-2 control-label">Status <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <select
                                                            name="status"
                                                            className="form-control"
                                                            id="status"
                                                            option={formValues.status}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}>
                                                            <option value="">Select Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">InActive</option>
                                                        </select>
                                                        {((errors.status && touched.status) || (errors.status && touched.formValues)) ? (<p className='text-danger'>{errors.status}</p>) : null}
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <div className="col-sm-4 col-sm-offset-2 group-btns">
                                                        <NavLink className="btn btn-white" to={'/status/list'}>Cancel</NavLink>
                                                        <button className="btn btn-primary" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div >
        </>
    )
}
