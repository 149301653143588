import * as yup from 'yup';
const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'
const EditProfileSchema = yup.object().shape({
    name: yup.string()
        .min(2, 'Name must be at least 2 characters')
        .max(50, 'Name must be at most 50 characters')
        .matches(stringcheck, 'Please enter valid name. (Only alphabets are allowed)')
        .required('Please enter name'),
    password: yup.string()
        .min(6, 'Password must be at least 6 characters')
        .max(20, 'Password must be at most 20 characters')
        .matches(/^\S*$/, 'Please enter valid password')
        .nullable(),

    // password_confirmation: yup.string()
    //     .oneOf([yup.ref('password'), null], 'Passwords must match')

    password_confirmation: yup.string().when('password', {
        is: (password) => !!password,
        then: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your password'),
        otherwise: yup.string().nullable(),
    }),
});

export default EditProfileSchema;
