import React, { useEffect, useState } from 'react'
import LeftNavbar from '../Layout/LeftNavbar'
import Navbar from '../Layout/Navbar'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Footer from '../Layout/Footer'
import { useFormik } from 'formik'
import axios from 'axios'
import { getSalePersonList, salePersonUpdate } from '../../.endpoint'
import { getToken, toastSetting } from '../.comman'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup'
import ToastContainerDefault from '../ToastContainerDefault'

const formValues = {
    name: "",
    email: "",
    password: "",
    status: "1"
};
const EditSalePerson = () => {
    document.title = "Law Calibration -Edit SalePerson";
    const navigate = useNavigate();
    const param = useParams()
    const [recordId, setRecordId] = useState((param.id ? param.id : 0));
    const [recordDetail, setRecordDetail] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

    const EditSalePersonSchemas = yup.object().shape({
        name: yup.string()
            .min(2, 'Name must be at least 2 characters')
            .matches(stringcheck, 'Please enter your valid company name. (Only alphabets are allowed)')
            .required('Please enter name'),

        email: yup.string()
            .matches(mailFormat, 'Please enter a valid email (e.g. test@test.com)')
            .required('Please enter email'),

        // password: yup.string()
        //     .min(6, 'Password must be at least 6 characters')
        //     .required('Please enter password'),

        is_active: yup.string()
            .required('Please choose status'),
    });

    useEffect(() => {
        axios.get(getSalePersonList + '?id=' + recordId, { headers: headers })
            .then((res) => {
                setRecordDetail(res.data.data);
            })
            .catch((error) => {
                toast.error(error.message, toastSetting())
            })
    }, []);

    const handleOnSubmit = (values, action) => {
        // console.log('Submit--', values)
        axios({
            method: "POST",
            url: salePersonUpdate,
            data: values,
            headers: headers
        })
            .then(res => {

                if (res.data.success == true) {
                    toast.success(res.data.message, toastSetting())
                    setTimeout(() => {
                        action.resetForm({ values: formValues });
                        navigate("/sale-person-list");
                    }, 3000)
                }

            })
            .catch(error => {
                // console.log('error')
                toast.error(error.message, toastSetting());
            });
    };

    const formik = useFormik({
        initialValues: recordDetail,
        validationSchema: EditSalePersonSchemas,
        enableReinitialize: true,
        onSubmit: handleOnSubmit
    })

    return (
        <>
            <div id="wrapper">
                <LeftNavbar />

                <div id="page-wrapper" className="gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Edit Sale Person</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/sale-person-list'}>Sale Person List</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>Edit</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2">

                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Sale Person Form</h5>
                                        </div>

                                        <div className="ibox-content">
                                            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor='name' className="col-sm-2 control-label">Name <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            id='name'
                                                            name='name'
                                                            className="form-control"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.name && formik.errors.name && (
                                                            <p className='text-danger'>{formik.errors.name}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='email' className="col-sm-2 control-label">Email <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            id='email'
                                                            name='email'
                                                            className="form-control"
                                                            value={formik.values.email}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                        />
                                                        {formik.touched.email && formik.errors.email && (
                                                            <p className='text-danger'>{formik.errors.email}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor='password' className="col-sm-2 control-label">Password <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            id='password'
                                                            name='password'
                                                            className="form-control"
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                        />
                                                        {formik.touched.password && formik.errors.password && (
                                                            <p className='text-danger'>{formik.errors.password}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='is_active' className="col-sm-2 control-label">Status <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <select
                                                            name="is_active"
                                                            className="form-control"
                                                            id="is_active"
                                                            value={formik.values.is_active}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value="">Select Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">InActive</option>
                                                        </select>
                                                        {formik.touched.is_active && formik.errors.is_active && (
                                                            <p className='text-danger'>{formik.errors.is_active}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <div className="col-sm-4 col-sm-offset-2 group-btns">
                                                        <NavLink className="btn btn-white" to={'/sale-person-list'}>Cancel</NavLink>
                                                        <button className="btn btn-primary" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div >
            <ToastContainerDefault />
        </>
    )
}
export default EditSalePerson;
