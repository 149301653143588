import { useNavigate } from "react-router-dom";
import { isLogin } from "../.comman";
import { useEffect } from "react";

const Protected = (props) => {
    const navigate = useNavigate();
    const { Component } = props
    useEffect(() => {
        if (!isLogin()) {
            navigate('/');
        }

        // if (isAdmin) {
        //     navigate('/dashboard');
        // } else {
        //     navigate('/saleteam/dashboard');
        // }

    });
    return <> <Component /> </>
}

export default Protected;