import React, { useEffect, useState } from 'react'
import LeftNavbar from '../Layout/LeftNavbar'
import Navbar from '../Layout/Navbar'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Footer from '../Layout/Footer'
import { useFormik } from 'formik'
import axios from 'axios'
import { editStatus, fetchStatus, getProfile, updateProfile } from '../../.endpoint'
import { getToken, toastSetting } from '../.comman'
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup'
import EditProfileSchema from '../../schemas/editProfileSchemas'
// import ToastContainerDefault from '../ToastContainerDefault'

const formValues = {
    name: "",
    password: "",
    password_confirmation: "",
};
const Profile = () => {
    document.title = "Profile Edit | Law Calibration";
    const navigate = useNavigate();
    const [recordDetail, setRecordDetail] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'

    // const EditProfileSchemas = yup.object().shape({
    //     name: yup.string()
    //         .min(2, 'Name must be at least 2 characters')
    //         .max(50, 'Name must be at most 50 characters')
    //         .matches(stringcheck, 'Please enter valid name. (Only alphabets are allowed)')
    //         .required('Please enter name'),
    //     password: yup.string()
    //         .min(6, 'Password must be at least 6 characters')
    //         .max(20, 'Password must be at most 20 characters')
    //         .matches(/^\S*$/, 'Please enter valid password')
    //         .nullable(),


    //     password_confirmation: yup.string()
    //         .oneOf([yup.ref('password')], 'Confirm password must match'),


    // });

    //Get Status detail
    useEffect(() => {
        axios.get(getProfile, { headers: headers })
            .then((res) => {
                setRecordDetail(res.data.data);
            })
            .catch((error) => {
                toast.error(error.message, toastSetting())
            })
    }, []);

    //Edit Status function
    const handleOnSubmit = (values, action) => {
        console.log('submitStatus--', values);
        // console.log('password--', values.password);
        // console.log('password_confirmation--', values.password_confirmation);
        // if (values.password_confirmation == 'undefined') {
        //     console.log('True');
        // } else {
        //     console.log('Else');
        // }
        // return false;
        axios({
            method: "PUT",
            url: updateProfile,
            data: values,
            headers: headers
        })
            .then(res => {

                if (res.data.success == true) {

                    // Retrieve data from localStorage
                    const storedData = localStorage.getItem('user-info');
                    let storedArray = JSON.parse(storedData);
                    storedArray.name = res.data.data.name;
                    const dataToStore = JSON.stringify(storedArray);
                    localStorage.setItem('user-info', dataToStore);

                    toast.success(res.data.message, toastSetting())
                    setTimeout(() => {
                        action.resetForm({ values: formValues });
                        navigate("/dashboard");
                    }, 3000)
                } else if (res.data.success === false) {
                    if (res.data.data.name && res.data.data.name.length > 0) {
                        toast.error(res.data.data.name[0], toastSetting())
                        return false;
                    }
                    if (res.data.data.password && res.data.data.password.length > 0) {
                        toast.error(res.data.data.password[0], toastSetting())
                        return false;
                    }
                }

            })
            .catch(error => {
                toast.error(error.message, toastSetting());
            });
    };


    const formik = useFormik({
        initialValues: recordDetail,
        validationSchema: EditProfileSchema,
        enableReinitialize: true,
        onSubmit: handleOnSubmit
    })

    return (
        <>
            <div id="wrapper">
                <LeftNavbar />

                <div id="page-wrapper" className="gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Edit Profile</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>Edit Profile</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2">

                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Edit Profile Form</h5>
                                        </div>

                                        <div className="ibox-content">
                                            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor='name' className="col-sm-2 control-label">Name <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            id='name'
                                                            name='name'
                                                            className="form-control"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.name && formik.errors.name && (
                                                            <p className='text-danger'>{formik.errors.name}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='password' className="col-sm-2 control-label">Password <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="password"
                                                            id='password'
                                                            name='password'
                                                            className="form-control"
                                                            value={formik.values.password}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.password && formik.errors.password && (
                                                            <p className='text-danger'>{formik.errors.password}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='password_confirmation' className="col-sm-2 control-label">Confirm Password <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="password"
                                                            id='password_confirmation'
                                                            name='password_confirmation'
                                                            className="form-control"
                                                            value={formik.values.password_confirmation}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                                                            <p className='text-danger'>{formik.errors.password_confirmation}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group ">
                                                    <div className="col-sm-4 col-sm-offset-2 group-btns">
                                                        <NavLink className="btn btn-white" to={'/dashboard'}>Cancel</NavLink>
                                                        <button className="btn btn-primary" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div >
        </>
    )
}
export default Profile;
