import * as yup from 'yup'

const stringcheck = '^(?!.*[@~!#$%&,^,),(+=._-`*])(?!.*[0-9._]$)[a-zA-Z]'
var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
var passFormat = /^\S*$/;

const AddSalePersonSchemas = yup.object({
    name: yup.string()
        .min(2, 'Name must be at least 2 characters')
        .matches(stringcheck, 'Please enter your valid name. (Only alphabets are allowed)')
        .required('Please enter name'),

    email: yup.string()
        .matches(mailFormat, 'Please enter a valid email (e.g. test@test.com)')
        .required('Please enter email'),

    password: yup.string()
        .matches(passFormat, 'You can not use space in password')
        .min(6, 'Password must be at least 6 characters')
        .required('Please enter password'),

    status: yup.string()
        .required('Please choose status'),
});
export default AddSalePersonSchemas;