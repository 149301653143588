export const isLogin = () => {
    const token = localStorage.getItem('access_token');
    if (token !== null && token !== undefined) { return true; } else { return false; }
}

export const doLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user-info');
}

export const userName = () => {
    const userInfo = JSON.parse(localStorage.getItem('user-info'));
    if (userInfo !== null && userInfo !== undefined) { return userInfo.name; } else { return 'NA'; }
}

export const getToken = () => {
    const token = JSON.parse(localStorage.getItem("access_token"));
    if (token !== null && token !== undefined) { return token; } else { return 'NA'; }
}



export const isAdmin = () => {
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    if (userInfo !== null && userInfo !== undefined) {
        if (userInfo.role_id == 1) {
            return true;
        } else if (userInfo.role_id == 2) {
            return false;
        }
    } else {
        return null;
    }
}


export const userRole = () => {
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    if (userInfo !== null && userInfo !== undefined) {
        if (userInfo.role_id == 1) {
            return 'Admin';
        } else if (userInfo.role_id == 2) {
            return 'Sale Person';
        }
    } else {
        return 'NA';
    }
}

export const toastSetting = () => {
    return {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }
}
