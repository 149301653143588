import { useFormik } from 'formik';
import React from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { getToken, toastSetting } from './.comman';
import axios from 'axios';
import { forgotPassword } from '../.endpoint';
import { toast } from 'react-toastify';
import ToastContainerDefault from './ToastContainerDefault';

const formValues = {
    email: "",
};

function ForgotPassword() {
    document.title = "Law Calibration -Forgot Password";
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const ForgotPasswordSchemas = yup.object().shape({
        email: yup.string().email('Please enter valid email ID').required('Please enter email ID'),
    });

    const handleOnSubmit = (values, action) => {
        axios({
            method: "POST",
            url: forgotPassword,
            data: values,
            headers: headers
        }).then(res => {
            if (res.data.success === true) {
                toast.success(res.data.message, toastSetting());
                action.resetForm({ values: formValues });
            }
            if (res.data.success === false) {
                if (res.data.data.email && res.data.data.email.length > 0) {
                    toast.error(res.data.data.email[0], toastSetting());
                } else {
                    toast.error(res.data.message, toastSetting());
                }
            }
        }).error(error => {
            console.log('error', error);
            toast.error(error.message, toastSetting());
        })
    }

    const formik = useFormik({
        initialValues: formValues,
        validationSchema: ForgotPasswordSchemas,
        onSubmit: handleOnSubmit
    });



    return (
        <>
            <div className="login-wrapper">
                <div className="loginscreen  animated fadeInDown">
                    <div class="navbar-brand">
                        {/* <img src="./asset/img/site-logo.png" alt=""> */}
                        <img src='./asset/img/site-logo.png' />

                    </div>
                    {/* <h3>Please Enter You Email ID</h3> */}
                    <form className="m-t" role="form" action="dashboard_2.html" onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                name='email'
                                className="form-control"
                                placeholder="Please Enter Your Email ID"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <p className='text-danger'>{formik.errors.email}</p>
                            )}
                        </div>
                        <button type="submit" className="btn btn-primary block full-width m-b">Send Password Reset Link</button>
                        <Link to={'/'}><small>Login</small></Link>
                    </form>
                    {/* <p className="m-t"> <small>Law Calibration we app framework base on Bootstrap 3 &copy; 2014</small> </p> */}
                </div>
            </div>
            <ToastContainerDefault />
        </>
    )
}

export default ForgotPassword
