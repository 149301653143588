import { useFormik, Formik } from 'formik'
import * as yup from 'yup'

const TestFile = () => {
    const formik = useFormik({
        initialValues: {
            image: ""
        },
        validationSchema: yup.object({
            image: yup
                .mixed()
                .test("FILE_SIZE", "Only documents up to 1MB are permitted.",
                    (value) => value && value.size < 2097500)
                .test("FILE_TYPE", "invalid File.",
                    (value) => value && ['application/pdf'].includes(value.type)),
        }),
        onSubmit: () => {
            console.log('FormValues', formik.values)
        }
    })
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <input
                    type='file'
                    name='image'
                    onChange={(e) => formik.setFieldValue('image', e.target.files[0])}
                />
                {
                    formik.errors.image && (
                        <p style={{ color: "red" }}>{formik.errors.image}</p>
                    )
                }
                <button type='submit'>Save</button>
            </form>
        </>
    )
}
export default TestFile;
