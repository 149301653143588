import React, { useEffect, useState } from 'react'
import LeftNavbar from '../Layout/LeftNavbar'
import Navbar from '../Layout/Navbar'
import { Link, NavLink } from 'react-router-dom'
import Footer from '../Layout/Footer'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { getSalePersonActive, getSalePersonDelete, getSalePersonList, salePersonDelete } from '../../.endpoint'
import { getToken, toastSetting, isAdmin } from '../.comman'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
// import ToastContainerDefault from '../ToastContainerDefault'

export default function SalePersonList() {
    document.title = "Law Calibration -SalePersons List";
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pageNo, setPageNo] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const [lastRecord, setLastRecord] = useState(10);



    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageNo(currentPage);
        fetchData(currentPage);

        if (totalRecords > (pageSize * currentPage)) {
            setLastRecord(pageSize * currentPage)
        } else {
            setLastRecord(totalRecords)
        }

    }



    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }

    //fetch listing on page load
    useEffect(() => {
        setLoading(true)
        axios.get(getSalePersonList, { headers: headers })
            .then((res) => {
                const total = res.data.data.total;
                setTotalRecords(total);
                const pageLimit = res.data.data.per_page;
                setPageSize(pageLimit);
                setPageCount(Math.ceil(total / pageLimit));
                setItems(res.data.data.data);
                setLoading(false)
            })
            .catch((error) => {
                // console.log(error);
                toast.success(error, toastSetting())
            })
    }, [])

    //fetch listing data function
    const fetchData = (currentPage) => {
        setLoading(true)
        axios.get(getSalePersonList + "?page=" + currentPage, { headers: headers })
            .then((res) => {
                const total = res.data.data.total;
                setTotalRecords(total);
                const pageLimit = res.data.data.per_page;
                setPageSize(pageLimit);
                setPageCount(Math.ceil(total / pageLimit));
                setItems(res.data.data.data);
                setLoading(false)
            })
            .catch((error) => { console.log(error); })
    }

    const handleDeleteEvent = (recordId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get(salePersonDelete + "?id=" + recordId, { headers: headers })
                    .then((res) => {
                        fetchData(1);
                        toast.success(res.data.message, toastSetting())
                    })
                    .catch((error) => {
                        toast.error(error.data.message, toastSetting());
                    })


            }
        })
    }

    const handleStatus = ({ target }, recordId) => {
        axios.post(getSalePersonActive, { salePersonId: recordId }, { headers: headers })
            .then((res) => {
                if (res.data.data.is_active == '1') {
                    target.classList.remove('btn-danger')
                    target.classList.add('btn-primary');
                    target.innerHTML = 'Active';
                } else {
                    target.classList.remove('btn-primary');
                    target.classList.add('btn-danger');
                    target.innerHTML = 'InActive';
                }
                toast.success(res.data.message, toastSetting())
            })
            .catch((error) => {
                console.log('error--', error)
                toast.error(error.data.message, toastSetting());
            })
    }

    return (
        <>
            <div id="wrapper">
                <LeftNavbar />
                <div id="page-wrapper" className="gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Sale Person List</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>SalePerson list</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2 ">
                                <Link to={"/add-sale-person"}>
                                    <button type="button" className="btn btn-w-m btn-primary">Add New</button>
                                </Link>
                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Sale Person Tables</h5>
                                        </div>
                                        <div className="ibox-content">

                                            <table className="table table-striped table-bordered table-hover dataTables-example" >
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Created At</th>
                                                        <th>Updated At</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        items.length > 0 ? (
                                                            items.map((item, i) => {

                                                                return (<tr className="gradeC" key={i}>
                                                                    <td>{((pageNo - 1) * 10 + (i + 1))}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.created_date}</td>
                                                                    <td>{item.updated_date}</td>
                                                                    <td>
                                                                        <span onClick={(e) => handleStatus(e, item.id)} className={'btn btn-xs ' + (item.is_active == '1' ? 'btn-primary' : 'btn-danger')}> {item.is_active == '1' ? 'Active' : 'InActive'}</span>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={'/sale-person-edit/' + item.id}><button className="btn btn-outline btn-primary dim" type="button"><i className="fa fa-edit"></i></button></Link>
                                                                        <button onClick={() => handleDeleteEvent(item.id)} className="btn btn-outline btn-danger  dim " type="button"><i className="fa fa-trash"></i></button>
                                                                    </td>
                                                                </tr>)
                                                            })
                                                        ) : (
                                                            <tr className="gradeC">
                                                                <td colSpan={7}>{loading ? <p>Loading...</p> : "Data Not available"}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div class="pagination-Wrapper">
                                                <ReactPaginate
                                                    previousLabel={'<<'}
                                                    nextLabel={'>>'}
                                                    breakLabel={'...'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={'pagination'}
                                                    pageClassName={'paginate_button page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'paginate_button page-item previous'}
                                                    nextClassName={'paginate_button page-item next'}
                                                    previousLinkClassName={'page-link'}
                                                    nextLinkClassName={'page-link'}
                                                    breakClassName={'page-link'}
                                                    breakLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                />
                                                {/* <p>Showing {((pageNo * pageSize) - pageSize) + 1} to {(pageNo * pageSize)} of {totalRecords} records</p> */}
                                                <p>Showing {((pageNo * pageSize) - pageSize) + 1} to {(lastRecord)} of {totalRecords} records</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        </>
    )
}

