import React from 'react'
import { NavLink } from 'react-router-dom'
import ToastContainerDefault from '../ToastContainerDefault'
const Footer = () => {

  return (
    <>
      <ToastContainerDefault />
      <div className="footer">
        <div className="pull-right">
          <NavLink to={'https://www.stackblue.com/'} target="_blank">Stack<strong>Blue</strong></NavLink>
        </div>
        <div>
          <strong>Copyright</strong> lawcalibration &copy; {(new Date().getFullYear())}-{(new Date().getFullYear() + 1)}
        </div>
      </div>

    </>
  )
}

export default Footer
