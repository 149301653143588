import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { isAdmin, userName, userRole } from '../.comman'


const LeftNavbar = () => {
    return (
        <>
            <nav className="navbar-default navbar-static-side" role="navigation">
                <div className="sidebar-collapse">
                    <ul className="nav" id="side-menu">
                        <li className="nav-header">
                            <div className="dropdown profile-element"> <span>
                                {/* <img alt="image" className="img-circle" src="../asset/img/profile_small.jpg" /> */}
                                <img alt="image" className="site-logo" src="../asset/img/site-logo.png" />
                            </span>
                                <a data-toggle="dropdown" className="dropdown-toggle" >
                                    <span className="clear"> <span className="block m-t-xs"> <strong className="font-bold">{userName()} </strong>
                                    </span> <span className="text-muted text-xs block">{userRole()} <b className="caret"></b></span> </span> </a>
                                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                    {/* <li> <Link to={'/profile'}>My Profile</Link></li> */}
                                    <li> <Link to={'/logout'}>Logout</Link></li>
                                </ul>
                            </div>
                            <div className="logo-element">
                                IN+
                            </div>
                        </li>

                        <li><NavLink to={"/dashboard"} ><i className="fa fa-th-large"></i><span className="nav-label">Dashboard</span></NavLink></li>
                        <li> <NavLink to={'/lead-listing'}>Lead List</NavLink></li>
                        {isAdmin() && (
                            <>
                                <li> <NavLink to={'/sale-person-list'}>Sale Person List</NavLink></li>
                                <li> <NavLink to={'/status/list'}>Status List</NavLink></li>
                            </>
                        )}
                    </ul>
                </div>
            </nav>

        </>
    )
}

export default LeftNavbar
