import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { getToken, isAdmin, toastSetting } from '../.comman';
import axios from 'axios';
import { assignLead, changeStatus, getActiveSalePerson, getLeadDetail, getStatus } from '../../.endpoint';
import LeftNavbar from '../Layout/LeftNavbar';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ToastContainerDefault from '../ToastContainerDefault';
import * as yup from 'yup'
import 'react-toastify/dist/ReactToastify.css';
import { useTransition } from 'react';

const formValues = {
    salePersonId: "",
    leadId: "",
    leadStatus: "",
    admincomment: "",
};

const SaleformValues = {
    leadStatus: "",
    leadId: "",
    salecomment: "",
};
export default function Leaddetail() {
    document.title = "Law Calibration -Lead Details";
    const navigate = useNavigate();
    const param = useParams();
    const [leadId, setLeadId] = useState((param.id ? param.id : 0));
    const [leadDetail, setLeadDetail] = useState([]);
    const [isPending, startTransition] = useTransition();

    const [productList, setProductList] = useState([]);
    const [salePersonList, setSalePersonList] = useState([]);
    const [leadStatusList, setLeadStatusList] = useState([]);
    const [editableValues, setEditableValues] = useState({
        salePersonId: "",
        leadId: "",
        leadStatus: "",
        admincomment: "",
    });
    const [editableSaleValues, setEditableSaleValues] = useState({
        leadStatus: "",
        leadId: "",
        salecomment: "",
    });

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }


    useEffect(() => {
        //Get lead detail by ID
        axios.get(getLeadDetail + '/' + leadId, { headers: headers })
            .then((res) => {
                var leadData = res.data.data || [];
                setProductList(res.data.data.product_list_array ?? []);
                startTransition(() => {
                    setLeadDetail(res.data.data);
                    setEditableValues({
                        salePersonId: leadData.sales_man_id,
                        leadId: leadData.id,
                        leadStatus: leadData.status,
                        admincomment: leadData.admin_comment,
                    });
                    setEditableSaleValues({
                        leadStatus: leadData.status,
                        leadId: leadData.id,
                        salecomment: leadData.sale_comment,
                    });
                });
            })
            .catch((error) => {
                toast.error(error.message, toastSetting())
            })
    }, [])

    useEffect(() => {
        // Get All active sale person list
        axios.get(getActiveSalePerson, { headers: headers })
            .then((res) => {
                setSalePersonList(res.data.data);
            })
            .catch((error) => { toast.error(error.message, toastSetting()) })
    }, [])

    useEffect(() => {
        //Get All status list
        axios.get(getStatus, { headers: headers })
            .then((res) => {
                setLeadStatusList(res.data.data);
            })
            .catch((error) => { toast.error(error.message, toastSetting()) })
    }, [])


    const AssignSalePersonSchemas = yup.object().shape({
        admincomment: yup.string()
            .nullable()
            .default('')
            .max(250, 'Maximum comment length is 250 characters')
            .test('no-sql-injection', 'SQL injection is not allowed', (value) => {
                // Check for common SQL injection patterns
                const sqlInjectionPatterns = [
                    /(\b(union)\b\s*[\(\)]*.*\b(select|update|delete|insert)\b)|(\b(select|update|delete|insert)\b.*\b(from|where|order\sby|group\sby|having)\b)/i,
                    /(\bexec\b\s*[\(\)]*.*\b(select|update|delete|insert)\b)|(\b(select|update|delete|insert)\b.*\b(from|where|order\sby|group\sby|having)\b)/i,
                    /(\b(sp_executesql)\b\s*[\(\)]*.*\b(select|update|delete|insert)\b)|(\b(select|update|delete|insert)\b.*\b(from|where|order\sby|group\sby|having)\b)/i
                ];
                return !sqlInjectionPatterns.some(pattern => pattern.test(value));
            })
            .test('no-html-tags', 'HTML tags are not allowed', (value) => {
                // Check if the value contains any HTML tags
                return !/<[^>]*>/i.test(value);
            }),
        leadStatus: yup.string().required('Please select lead status'),
    });

    const SaleStatusSchemas = yup.object().shape({
        salecomment: yup.string()
            .nullable()
            .default('')
            .max(250, 'Maximum comment length is 250 characters')
            .test('no-sql-injection', 'SQL injection is not allowed', (value) => {
                // Check for common SQL injection patterns
                const sqlInjectionPatterns = [
                    /(\b(union)\b\s*[\(\)]*.*\b(select|update|delete|insert)\b)|(\b(select|update|delete|insert)\b.*\b(from|where|order\sby|group\sby|having)\b)/i,
                    /(\bexec\b\s*[\(\)]*.*\b(select|update|delete|insert)\b)|(\b(select|update|delete|insert)\b.*\b(from|where|order\sby|group\sby|having)\b)/i,
                    /(\b(sp_executesql)\b\s*[\(\)]*.*\b(select|update|delete|insert)\b)|(\b(select|update|delete|insert)\b.*\b(from|where|order\sby|group\sby|having)\b)/i
                ];
                return !sqlInjectionPatterns.some(pattern => pattern.test(value));
            })
            .test('no-html-tags', 'HTML tags are not allowed', (value) => {
                // Check if the value contains any HTML tags
                return !/<[^>]*>/i.test(value);
            }),
        leadStatus: yup.string().required('Please select lead status'),
    });

    const handleOnSubmit = (values, action) => {
        values.leadId = leadId;
        axios({
            method: "POST",
            url: assignLead,
            data: values,
            headers: headers
        })
            .then((res) => {
                if (res.data.success === true) {
                    toast.success(res.data.message, toastSetting())
                    action.resetForm({ values: formValues });
                    setTimeout(() => navigate("/lead-listing"), 2000);
                }
                if (res.data.success === false) {
                    if (res.data.message === 'Validation Failed') {
                        const errorMessages = Object.values(res.data.data).flat();
                        toast.error(errorMessages.join(', '), toastSetting());
                        // toast.error(res.data.message, toastSetting())
                    } else {
                        toast.error(res.data.message, toastSetting())
                    }
                }
            })
            .catch(error => {
                toast.error(error.message, toastSetting());
            });
    };

    const handleOnSubmitStatusChange = (values, action) => {
        values.leadId = leadId;
        console.log('handleOnSubmitStatusChange', values);

        axios({
            method: "POST",
            url: changeStatus,
            data: values,
            headers: headers
        })
            .then((res) => {
                if (res.data.success === true) {
                    toast.success(res.data.message, toastSetting())
                    action.resetForm({ values: formValues });
                    setTimeout(() => navigate("/lead-listing"), 2000);
                }
                if (res.data.success === false) {
                    if (res.data.message === 'Validation Failed') {
                        const errorMessages = Object.values(res.data.data).flat();
                        toast.error(errorMessages.join(', '), toastSetting());
                        // toast.error(res.data.message, toastSetting())
                    } else {
                        toast.error(res.data.message, toastSetting())
                    }
                }
            })
            .catch(error => {
                toast.error(error.message, toastSetting());
            });
    };

    const formik = useFormik({
        initialValues: editableValues,
        validationSchema: AssignSalePersonSchemas,
        enableReinitialize: true,
        onSubmit: handleOnSubmit
    })

    const Saleformik = useFormik({
        initialValues: editableSaleValues,
        validationSchema: SaleStatusSchemas,
        enableReinitialize: true,
        onSubmit: handleOnSubmitStatusChange
    })
    if (!leadDetail) return null;
    return (
        <>
            <div id="wrapper">
                <LeftNavbar />

                <div id="page-wrapper" className="lead-details gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Lead Detail</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/lead-listing'}>Lead Detail</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>Lead Detail</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2">

                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Lead Detail</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <div className="form-group">
                                                <label htmlFor='service_ty,pe' className="col-sm-2 control-label">Service Type </label>
                                                <span>{leadDetail.service_type_name}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='company' className="col-sm-2 control-label">Company Name </label>
                                                <span>{leadDetail.customer_name}</span>
                                            </div>
                                            {/* <div className="form-group">
                                                <label htmlFor='lname' className="col-sm-2 control-label">Address</label>
                                                <span>{leadDetail.c_address}</span>
                                            </div> */}
                                            <div className="form-group">
                                                <label htmlFor='fname' className="col-sm-2 control-label">Point Of Contact</label>
                                                <span>{leadDetail.point_of_contact}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='lname' className="col-sm-2 control-label">Address</label>
                                                <span>{leadDetail.poc_address}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='city' className="col-sm-2 control-label">City </label>
                                                <span>{leadDetail.city_name}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='state' className="col-sm-2 control-label">State</label>
                                                <span>{leadDetail.state_name}</span>
                                            </div>
                                            {/* <div className="form-group">
                                                <label htmlFor='country' className="col-sm-2 control-label">Country</label>
                                                <span>{leadDetail.country_name}</span>
                                            </div> */}
                                            <div className="form-group">
                                                <label htmlFor='zmipCode' className="col-sm-2 control-label">Zip-Code</label>
                                                <span>{leadDetail.zip_code}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor='email' className="col-sm-2 control-label">Email</label>
                                                <span>{leadDetail.email}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='phone' className="col-sm-2 control-label">Phone No.</label>
                                                <span>{leadDetail.phone_no}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor='File' className="col-sm-2 control-label">File</label>
                                                {leadDetail.pdt_file ? (
                                                    <Link target='_block' to={leadDetail.pdt_file}>{leadDetail.files}</Link>
                                                ) : (null)
                                                }
                                            </div>
                                            {/* <div className="form-group">
                                                <label htmlFor='productList' className="col-sm-2 control-label">Product List</label>
                                                <span>{leadDetail.product_list}</span>
                                            </div> */}
                                            {
                                                productList.length > 0 ? (
                                                    <React.Fragment>
                                                        <div className="form-group no-hover grid-full">
                                                            <div className="table-wrapper">
                                                                <label>Product List</label>
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Description</th>
                                                                            <th>Make</th>
                                                                            <th>Model</th>
                                                                            <th>Serial</th>
                                                                            <th>ID</th>
                                                                            <th>Location</th>
                                                                            <th>Cycle</th>
                                                                            <th>Notes</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            productList?.map((item, i) => {
                                                                                return (<tr className="gradeC" key={i}>
                                                                                    <td>{item.description}</td>
                                                                                    <td>{item.make}</td>
                                                                                    <td>{item.model}</td>
                                                                                    <td>{item.serial}</td>
                                                                                    <td>{item.id}</td>
                                                                                    <td>{item.location}</td>
                                                                                    <td>{item.cycle}</td>
                                                                                    <td>{item.notes}</td>
                                                                                </tr>)
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (null)
                                            }

                                            <div className="form-group">
                                                <label htmlFor='comments' className="col-sm-2 control-label">Comment </label>
                                                <span>{leadDetail.comments}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='expedited' className="col-sm-2 control-label">Expedited Options</label>
                                                <span>{leadDetail.expedited_options_name}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='salePerson' className="col-sm-2 control-label">Sale Person</label>
                                                <span>{leadDetail.sales_man_name}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='status' className="col-sm-2 control-label">Status</label>
                                                <span>{leadDetail.status}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='additinalServices' className="col-sm-2 control-label">Additional Services</label>
                                                <span>{leadDetail.additional_services_name}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor='repairServices' className="col-sm-2 control-label">Repair Services + Calibration Charges</label>
                                                {leadDetail.repair_service_obj && leadDetail.repair_service_obj.map((repairService, index) => (
                                                    <span key={index}>{repairService.name}</span>
                                                ))}

                                            </div>
                                            {isAdmin() ? (
                                                <>
                                                    <div className="grid-full cus-comment"  >
                                                        <label htmlFor='salecmt' className="col-sm-12 control-label">Sale Person Comment</label>
                                                        <span>{leadDetail.sale_comment}</span>
                                                    </div>
                                                    <form className="grid-full" onSubmit={formik.handleSubmit}>
                                                        <div className='form-group'>
                                                            <label htmlFor='admincomment' className="control-label">Comment</label>
                                                            <textarea
                                                                className="form-control m-b"
                                                                name="admincomment"
                                                                style={{ width: "100%" }}
                                                                value={formik.values.admincomment}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                            >{leadDetail.admin_comment}</textarea>
                                                            {(formik.errors.admincomment && formik.touched.admincomment) || (formik.errors.admincomment && formik.touched.formValues) ? (<p className='text-danger'>{formik.errors.admincomment}</p>) : null}
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className='form-group'>
                                                                    <label htmlFor='leadStatus' className="control-label">Status </label>
                                                                    <select
                                                                        className="form-control m-b"
                                                                        name="leadStatus"
                                                                        style={{ width: "100%" }}
                                                                        value={formik.values.leadStatus}  // Set the initial value based on formik values
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        {leadStatusList.map((statusObj, index) => (
                                                                            <option
                                                                                key={index}
                                                                                value={statusObj.name}
                                                                                selected={leadDetail.status && leadDetail.status === statusObj.name}
                                                                            >
                                                                                {statusObj.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {(formik.errors.leadStatus && formik.touched.leadStatus) || (formik.errors.leadStatus && formik.touched.formValues) ? (<p className='text-danger'>{formik.errors.leadStatus}</p>) : null}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 cus-pl-0" >
                                                                <div className='form-group'>
                                                                    <label htmlFor='salePersonId' className="control-label">Assign Sale Person </label>
                                                                    <input type="hidden" name='leadId' value={leadId} />
                                                                    <select
                                                                        className="form-control m-b"
                                                                        name="salePersonId"
                                                                        style={{ width: "100%" }}
                                                                        value={formik.values.salePersonId}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                    >
                                                                        <option value="">Select Sale Person</option>
                                                                        {salePersonList.map((getPerson, index) => (
                                                                            <option
                                                                                key={index}
                                                                                value={getPerson.id}
                                                                                selected={leadDetail.assign_lead && leadDetail.assign_lead['saleperson_id'] === getPerson.id}
                                                                            >
                                                                                {getPerson.name}
                                                                            </option>
                                                                        ))}

                                                                    </select>
                                                                    {((formik.errors.salePersonId && formik.touched.salePersonId) || (formik.errors.salePersonId && formik.touched.formValues)) ? (<p className='text-danger'>{formik.errors.salePersonId}</p>) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-btns ">
                                                            <div className="group-btns justify-content-end">
                                                                <NavLink className="btn btn-white" to={'/lead-listing'}>Cancel</NavLink>
                                                                <button className="btn btn-primary" type="submit">Save</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="grid-full  cus-comment">
                                                        <label htmlFor='admincmt' className="col-sm-12 control-label">Admin Comment</label>
                                                        <span>{leadDetail.admin_comment}</span>
                                                    </div>
                                                    <form className="grid-full" onSubmit={Saleformik.handleSubmit}>
                                                        <div className='form-group'>
                                                            <label htmlFor='salecomment' className="control-label">Comment</label>
                                                            <textarea
                                                                className="form-control m-b"
                                                                name="salecomment"
                                                                style={{ width: "100%" }}
                                                                value={Saleformik.values.salecomment}
                                                                onBlur={Saleformik.handleBlur}
                                                                onChange={Saleformik.handleChange}
                                                            >{leadDetail.sale_comment}</textarea>
                                                            {(Saleformik.errors.salecomment && Saleformik.touched.salecomment) || (Saleformik.errors.salecomment && Saleformik.touched.formValues) ? (<p className='text-danger'>{Saleformik.errors.salecomment}</p>) : null}
                                                        </div>
                                                        <div className='form-group'>
                                                            <label htmlFor='leadStatus' className="control-label">Change Status</label>
                                                            <input type="hidden" name='leadId' value={leadId} />
                                                            <select
                                                                className="form-control m-b"
                                                                name="leadStatus"
                                                                style={{ width: "100%" }}
                                                                value={Saleformik.values.leadStatus}  // Set the initial value based on formik values
                                                                onBlur={Saleformik.handleBlur}
                                                                onChange={Saleformik.handleChange}
                                                            >
                                                                <option value="">Select Status</option>
                                                                {leadStatusList.map((statusObj, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={statusObj.name}
                                                                        selected={leadDetail.status === statusObj.name}
                                                                    >
                                                                        {statusObj.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {((Saleformik.errors.leadStatus && Saleformik.touched.leadStatus) || (Saleformik.errors.leadStatus && Saleformik.touched.SaleformValues)) ? (<p className='text-danger'>{Saleformik.errors.leadStatus}</p>) : null}
                                                        </div>
                                                        <div className="form-btns">
                                                            <div className="group-btns justify-content-center">
                                                                <NavLink className="btn btn-white" to={'/lead-listing'}>Cancel</NavLink>
                                                                <button className="btn btn-primary" type="submit">Save</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div >
            <ToastContainerDefault />
        </>
    )
}
