import { useNavigate } from 'react-router-dom'
import { doLogout, isLogin } from '../.comman'


export default function Logout() {
    const navigate = useNavigate();
    doLogout();
    if (isLogin()) {
        navigate('login');
    } else {
        navigate('/login');
    }

}
