import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import loginForm from "../schemas/Loginschemas";
import axios from "axios";
import { loginUrl } from "../.endpoint";
import { toast } from "react-toastify";
import { toastSetting } from "./.comman";
import ToastContainerDefault from "./ToastContainerDefault";

const formValues = {
  userEmail: "",
  userPass: "",
};
const Login = () => {
  document.title = "Law Calibration -Login";
  const Redirect = useNavigate();

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: { formValues },
      validationSchema: loginForm,
      onSubmit: (values, action) => {
        axios
          .post(loginUrl, values)
          .then((res) => {
            const result = res.data.data;
            if (res.data.success === true) {
              localStorage.setItem("user-info", JSON.stringify(result.data));
              localStorage.setItem(
                "access_token",
                JSON.stringify(result.token)
              );
              Redirect("/dashboard");
            }
            if (res.data.success === false) {
              toast.error(res.data.message, toastSetting());
            }
          })
          .catch((error) => {
            console.log("errorLogin->", error);
            toast.error("Login credentials invalid", toastSetting());
          });
      },
    });
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="loginscreen  animated fadeInDown">
          {/* <h1 className="logo-name">LC</h1> */}
          <div class="navbar-brand">
            {/* <img src="./asset/img/site-logo.png" alt=""> */}
            <img src="./asset/img/site-logo.png" />
          </div>
          {/* <h3>Welcome to LAW-CALIBRATION</h3> */}
          <form className="m-t" method="post" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                name="userEmail"
                className="form-control"
                placeholder="Username"
                value={values.userEmail || ""}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {(errors.userEmail && touched.userEmail) ||
              (errors.userEmail && touched.formValues) ? (
                <p className="text-danger">{errors.userEmail}</p>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="password"
                onKeyDown={handleKeyDown}
                name="userPass"
                className="form-control"
                placeholder="Password"
                value={values.userPass || ""}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {(errors.userPass && touched.userPass) ||
              (errors.userPass && touched.values) ? (
                <p className="text-danger">{errors.userPass}</p>
              ) : null}
            </div>
            <button
              type="submit"
              className="btn btn-primary block full-width m-b"
            >
              Login
            </button>

            <Link to={"/forgot-password"}>
              <small>Forgot password?</small>
            </Link>
            <br />
            {/* <p className="text-muted text-center"><small>Do not have an account?</small></p> */}
          </form>
          {/* <p className="m-t"> <small>Law Calibration we app framework base on Bootstrap 3 &copy; 2014</small> </p> */}
        </div>
      </div>
      <ToastContainerDefault />
    </>
  );
};

export default Login;
