// const baseUrl = process.env.REACT_APP_BASE_PATH_LOCAL;
// const baseUrl = process.env.REACT_APP_BASE_PATH_DEV;
const baseUrl = process.env.REACT_APP_BASE_PATH_LIVE;

// export const siteKey = process.env.REACT_APP_SITE_KEY_LOCAL;
// export const siteKey = process.env.REACT_APP_SITE_KEY_DEV;
export const siteKey = process.env.REACT_APP_SITE_KEY_LIVE;

export const loginUrl = baseUrl + 'api/user-login';
export const forgotPassword = baseUrl + 'api/forgot-password';
export const resetPassword = baseUrl + 'api/reset-password';
export const getCountry = baseUrl + 'api/country';
export const leadSubmit = baseUrl + 'api/lead-submit';
export const getLeadList = baseUrl + 'api/lead-listing';
export const getLeadDetail = baseUrl + 'api/lead-detail';
export const addSalePerson = baseUrl + 'api/create-sale-person';
export const getSalePersonList = baseUrl + 'api/get-sale-person-list';
export const getActiveSalePerson = baseUrl + 'api/get-active-sale-person';
export const getSalePersonActive = baseUrl + 'api/sale-person-active';
export const salePersonUpdate = baseUrl + 'api/sale-person-update';
export const salePersonDelete = baseUrl + 'api/sale-person-destroy';
export const assignLead = baseUrl + 'api/assign-lead';
export const getAssignLeadList = baseUrl + 'api/get-assign-lead';
export const getAdminDashBoard = baseUrl + 'api/landing-page';
export const getAllLeads = baseUrl + 'api/all-lead';
export const getStatus = baseUrl + 'api/get-status';
export const changeStatus = baseUrl + 'api/change-status';
export const leadDelete = baseUrl + 'api/trash';
export const downloadCsvLink = baseUrl + 'api/getcsv';
//Profile Management End points
export const getProfile = baseUrl + 'api/profile';
export const updateProfile = baseUrl + 'api/profile/edit';
// Status Management End Points
export const fetchStatus = baseUrl + 'api/status/list';
export const addStatus = baseUrl + 'api/status/add';
export const editStatus = baseUrl + 'api/status/edit';
export const changeRecordStatus = baseUrl + 'api/status/change';
export const trashStatus = baseUrl + 'api/status/trash/';
