import * as yup from 'yup'

const stringcheck = '^[a-zA-Z0-9]'

const loginForm = yup.object({
    userEmail: yup.string().email('Please enter a valid email')
        .required('Please enter email'),

    userPass: yup.string().min(6, 'Password must be at least 6 characters')
        .matches(stringcheck, 'Special character & whitespace is not allowed')
        .required('Please enter password'),
});
export default loginForm;