import React from 'react'
import LeftNavbar from '../Layout/LeftNavbar'
import Navbar from '../Layout/Navbar'
import { NavLink, useNavigate } from 'react-router-dom'
import Footer from '../Layout/Footer'
import { useFormik } from 'formik'
import AddSalePersonSchemas from '../../schemas/AddSalePersonSchemas'
import axios from 'axios'
import { addSalePerson } from '../../.endpoint'
import { getToken, toastSetting } from '../.comman'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContainerDefault from '../ToastContainerDefault'

const formValues = {
    name: "",
    email: "",
    password: "",
    status: "1"
};
export default function AddSalePerson() {
    document.title = "Add Sale Person | Law Calibration";
    const navigate = useNavigate();
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: { formValues },
        validationSchema: AddSalePersonSchemas,
        onSubmit: (values, action) => {
            axios.post(addSalePerson, values, { headers: headers })
                .then((res) => {
                    console.log('error--->', res.data);
                    if (res.data.success === true) {
                        toast.success(res.data.message, toastSetting())
                        setTimeout(() => {
                            action.resetForm({ values: formValues });
                            navigate("/sale-person-list");
                        }, 3000)
                    } else if (res.data.success === false) {
                        if (res.data.data.email && res.data.data.email.length > 0) {
                            toast.error(res.data.data.email[0], toastSetting())
                            return false;
                        }
                    }
                })
                .catch((error) => { toast.error(error.message, toastSetting()); })
            // action.resetForm({ values: formValues });
        }
    });

    return (
        <>
            <div id="wrapper">
                <LeftNavbar />

                <div id="page-wrapper" className="gray-bg">
                    <Navbar />
                    <div className="wrapper wrapper-content">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-10">
                                <h2>Add Sale Person</h2>
                                <ol className="breadcrumb">
                                    <li>
                                        <NavLink to={'/dashboard'}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/sale-person-list'}>Sale Person List</NavLink>
                                    </li>
                                    <li className="active">
                                        <strong>Add New</strong>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-2">

                            </div>
                        </div>
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Sale Person Form</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <form className="form-horizontal" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor='name' className="col-sm-2 control-label">Name <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input type="text" id='name' name='name' className="form-control" value={values.name} onBlur={handleBlur} onChange={handleChange} />
                                                        {((errors.name && touched.name) || (errors.name && touched.formValues)) ? (<p className='text-danger'>{errors.name}</p>) : null}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='email' className="col-sm-2 control-label">Email <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input type="text" id='email' name='email' className="form-control" value={values.email} onBlur={handleBlur} onChange={handleChange} />
                                                        {((errors.email && touched.email) || (errors.email && touched.formValues)) ? (<p className='text-danger'>{errors.email}</p>) : null}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='password' className="col-sm-2 control-label">Password <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <input type="text" id='password' name='password' className="form-control" value={values.password} onBlur={handleBlur} onChange={handleChange} />
                                                        {((errors.password && touched.password) || (errors.password && touched.formValues)) ? (<p className='text-danger'>{errors.password}</p>) : null}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor='status' className="col-sm-2 control-label">Status <sup >*</sup></label>
                                                    <div className="col-sm-6">
                                                        <select name="status" className="form-control" id="status" option={formValues.status} onBlur={handleBlur} onChange={handleChange}>
                                                            <option value="">Select Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">InActive</option>
                                                        </select>
                                                        {((errors.status && touched.status) || (errors.status && touched.formValues)) ? (<p className='text-danger'>{errors.status}</p>) : null}
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    <div className="col-sm-4 col-sm-offset-2 group-btns">
                                                        <NavLink className="btn btn-white" to={'/sale-person-list'}>Cancel</NavLink>
                                                        <button className="btn btn-primary" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div >
            <ToastContainerDefault />
        </>
    )
}
