import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContainerDefault from './ToastContainerDefault'
import { getToken, toastSetting } from './.comman';
import * as yup from 'yup'
import { resetPassword } from '../.endpoint';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
const formValues = {
    upass: "",
    cupass: "",
};
function ResetPassword() {
    document.title = "Law Calibration -Reset Password";
    const param = useParams()
    const [token, setToken] = useState((param.token ? param.token : null));
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ` + getToken(),
    }
    const ResetPassValidation = yup.object().shape({
        upass: yup.string()
            .min(6, 'Password must be at least 6 characters')
            .max(20, 'Password must be at most 20 characters')
            .matches(/^\S*$/, 'Please enter valid password')
            .required('Password is required'),
        cupass: yup.string()
            .min(6, 'Confirm password must be at least 6 characters')
            .max(20, 'Confirm password must be at most 20 characters')
            .matches(/^\S*$/, 'Please enter valid confirm password')
            .oneOf([yup.ref('upass'), null], 'Passwords do not match'),
    });

    const handleOnSubmit = (values, action) => {
        values.token = token;
        axios({
            method: "POST",
            url: resetPassword,
            data: values,
            headers: headers
        }).then(res => {
            if (res.data.success === true) {
                toast.success(res.data.message, toastSetting());
                action.resetForm({ values: formValues });
            }
            if (res.data.success === false) {
                toast.error(res.data.message, toastSetting());
            }
        }).error(error => {
            toast.error(error.message, toastSetting());
        })
    }

    const formik = useFormik({
        initialValues: formValues,
        validationSchema: ResetPassValidation,
        onSubmit: handleOnSubmit
    });
    return (
        <>
            <div className='login-wrapper'>
                <div className="loginscreen  animated fadeInDown">
                    <div class="navbar-brand">
                        {/* <img src="./asset/img/site-logo.png" alt=""> */}
                        <img src='../asset/img/site-logo.png' />
                    </div>
                    <h3 style={{ color: "#fff", marginBlockStart: "2rem" }}>Please Reset Your Password</h3>
                    <form role="form" onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="password"
                                name='upass'
                                className="form-control"
                                placeholder="Enter password"
                                value={formik.values.upass}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.upass && formik.errors.upass && (
                                <p className='text-danger'>{formik.errors.upass}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                name='cupass'
                                className="form-control"
                                placeholder="Enter confirm password"
                                value={formik.values.cupass}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.cupass && formik.errors.cupass && (
                                <p className='text-danger'>{formik.errors.cupass}</p>
                            )}
                        </div>
                        <button type="submit" className="btn btn-primary block full-width m-b">Save Password</button>
                        <Link to={'/'}><small>Login</small></Link>
                    </form>
                    {/* <p className="m-t"> <small>Law Calibration we app framework base on Bootstrap 3 &copy; 2014</small> </p> */}
                </div>
            </div>
            <ToastContainerDefault />
        </>
    )
}

export default ResetPassword
